.typeWrap {
  font: var(--zrFontSemi);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.typeIcon {
  margin: 0 .25rem;
  height: 2rem;
}

.strainWrap {
  margin-bottom: 6px;
}

.strain {
  font-weight: 600;
}

.compounds {
  margin: .5rem 0 0;
  display: flex;
  flex-wrap: wrap;
}

.compound {
  font: var(--zrFontBold);
  font-size: 1.5rem;
  margin: .25rem 1rem .5rem 0;
}

.compoundLabel {
  font: var(--zrFontSemi);
  font-size: .875rem;
}

.totalTerpines {
  background-color: #0001;
  border-radius: 8px;
}

.totalTerpines .compound {
  margin: .25rem .5rem .5rem;
}

.disclaimor {
  font-size: .875rem;
}

@media screen and ( max-width:600px ) {
  .typeWrap {
    font-size: 1.25rem;
  }
  .compound {
    font-size: 1.25rem;
  }
}

.header {
  margin-top: 2rem !important;
}

.hdrLabel {
  padding-right: 4px;
}

.searchPageWrapper {
  position: relative;
  z-index: 1;
  width: 350px;
  margin: 1.5rem auto;
  max-width: 95vw;
}

.hintWrap {
  position: absolute;
  z-index: 0;
  bottom:0;
  left: 0;
  right: 0;
  padding: 50px 4px 20px;
  box-sizing: border-box;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 0%, rgba(174,212,245,1) 80%);
}

.hints {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.hint {
  display: block;
  white-space: nowrap;
  transform: rotate(-5deg);
  padding: 15px 4vw 0 2vw;
  font-weight: 600;
  font-size: 20px;
  color:#fffc;
}

.hintEven {
  padding: 15px 2vw 0 4vw;
}

@media screen and (max-width:600px) {
  .searchPageWrapper {
    margin: .75rem auto 1.5rem;
  }
  .hdrIcon {
    height: 26px !important;
  }
  .hints {
    max-height: 180px;
  }
  .hint {
    font-size: 16px;
  }
}

.searchInput {
  display: block;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 4px 8px 36px;
  font-size: 1.125rem;
  border-radius: 4px;
}

.searchInput::placeholder {
  font-weight: 400;
  font-size: 1rem;
  color: #777;
}

.searchIcon {
  position: absolute;
  z-index: 2;
  left: 8px;
  top: 8px;
  height: 24px;
}

.clear {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 4px;
  top: 5px;
  transform: rotate(45deg);
  font-size: 31px !important;
  color: var(--disabledGray);
  background-color: white;
  border-radius: 50%;
}

.resultBrand {
  white-space: nowrap;
}

.saleLabel {
  display: inline-block;
  vertical-align: 4px;
  margin-left: 1px;
  color: var(--zrTextRed);
  font-size: 13px;
  font-style: italic;
  line-height: 15px;;
}

/* suggestion result color styling */
.cbd {
  /* color: #411c5f; */
  background-color: #ddc6f155;
}

.sativa {
  /* color: #6b442e; */
  background-color: #f7905959;
}

.indica {
  /* color: #073051; */
  background-color:#6cb4fc55;
}

.indica-hybrid,
.sativa-hybrid,
.hybrid {
  /* color: #154b3b; */
  background-color:#32A28022;
}

/* TYPEAHEAD DEFAULT CSS (modified) */
:global .typeahead-standalone {
  display:flex;
  flex-direction:column;
  text-align:left
}

:global .typeahead-standalone .tt-input {
  border-style: solid;
  border-color: var(--zrLightBorder);
  z-index: 1;
  box-shadow: 0 0 6px var(--zrLightBorder);
}

:global .typeahead-standalone .tt-hint {
  cursor:default;
  -webkit-user-select:none;
  user-select:none;
  z-index:0;
  font-size: 1.125rem;
  padding: 8px 4px;
  margin-top: -41px;
}

:global .typeahead-standalone .tt-list {
  position:absolute;
  z-index:1;
  background:#fff;
  border:1px solid rgba(50,50,50,.6);
  box-sizing:border-box;
  overflow:auto;
  box-shadow: 0 2px 4px rgba(0,0,0,.4);
}

:global .typeahead-standalone .tt-list .tt-suggestion.tt-selected,
:global .typeahead-standalone .tt-list .tt-suggestion:hover {
  cursor:pointer;
}

:global .typeahead-standalone .tt-list .tt-suggestion .tt-highlight {
  font-weight:900;
}
/* END DEFAULT CSS */

/* typeahead custom ZR css */
/* tt-hide means search input has lost focus, but we persists results in some cases, see overrides */
:global .typeahead-standalone .tt-list.tt-hide,
:global .typeahead-standalone .tt-list:empty {
  display: none;
}

/* if the widget area is still "in use/focused" keep results displayed */
.focused :global .typeahead-standalone .tt-list.tt-hide {
  display: block;
}

/* except when there are no results (or search string is empty) */
.focused.empty :global .typeahead-standalone .tt-list,
.focused :global .typeahead-standalone .tt-list:empty {
  display: none;
}

:global .typeahead-standalone .suggestion-label {
  font-weight: 600;
  padding: 1px 4px 3px;
  border-bottom: 1px solid #ccc;
}

:global .tt-suggestion.tt-selected .suggestion-label {
  color: #fff;
  background-color: var(--zrPrimaryVeryDark);
}

/* Desktop only */
@media  screen and (min-width:800px) {
  /* .suggestion-label */
  :global .typeahead-standalone .tt-list .tt-suggestion:hover  {
    background-color: #0002;
  }
}

.successWrap {
  max-width: 600px;
  margin: 0 auto;
}
.successInner {
  padding: 2rem 0 2.5rem;
}
.successMsg {
  text-align: center;
  font: var(--zrFontBold);
  font-size: 1.675rem;
  margin-bottom: 2rem;
}
.exclaim {
  display: block;
  margin-bottom: .5rem;
  color: var(--zrPrimaryDark);
}
.accountTip {
  margin: 2rem 0.5rem 1.5rem;
  padding: 1rem .5rem;
  text-align: center;
  font: var(--zrFontSemi);
  font-size: .875rem;
  background-color: #f1f1f1;
  color: var(--zrPrimaryDark);
  border-radius: .5rem;
}
.accountTip img {
  display: block;
  margin: .5rem auto 0;
}
.referWrap {
  padding: 1rem;
  margin: .25rem .5rem 0;
  border-radius: .5rem;
  background-color: #dfedfe;
}

@media screen and (min-width:600px) {
  .successInner {
    padding: 2rem 1rem 3rem;
  }
  .successMsg {
    font-size: 2rem;
  }
  .exclaim {
    display: inline;
    margin-bottom: 0;
  }
  .accountTip {
    margin: 3rem .5rem 3rem;
  }
}

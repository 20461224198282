.strainDescription b {
  font-weight: 600;
}

.strainDescription :global b.typeH {
  color: #2b8f71;
}

.strainDescription :global b.typeI {
  color: var(--zrPrimaryDark);
}

.strainDescription :global b.typeS {
  color: #da5801;
}

.tabs {
  --hybridActive: #cee9de;
  --hybridInActive: #bad1c8;
  --indicaActive: #d5e9fd;
  --indicaInActive: #b4c9df;
  --sativaActive: #ffc8a8;
  --sativeInActive: #e5b598;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0 0 12px;
  margin-bottom: 10px;
  background: linear-gradient(to bottom, transparent 0%,#0002 100%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom: 1px solid #000;
}

.tabs .tab {
  position: relative;
  z-index: 2;
  border-top: 1px solid #000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px 12px 6px;
  margin-right: 8px;
  line-height: 100%;
}

/* Tab stacking order */
.tabs .activeTab {
  font-weight: 600;
  z-index: 3;
}

/* Active left border */
.tabs .tab::before {
  --angle: 20deg;
  content: '';
  position: absolute;
  top: 0; bottom: 0; left: -4px;
  width: 32px;
  z-index: -1;
  border-left: 1px solid #000;
  border-right: none;
  border-bottom: none;
  border-radius: 7px 0 0 0;
  transform: skewX(calc(-1 * var(--angle)));
}

/* Active left border */
.tabs .tab::after {
  --angle: 20deg;
  content: '';
  position: absolute;
  top: 0; bottom: 0; right: -4px;
  width: 32px;
  z-index: -1;
  border-right: 1px solid #000;
  border-left: none;
  border-bottom: none;
  border-radius: 0 7px 0 0;
  /* transform: scale(1.2, 1.3) perspective(.5em) rotateX(5deg); */
  transform: skewX(calc(var(--angle)));
}

/* Remove tab bottom order when active */
.tabs .activeTab .tabBottom {
  position: absolute;
  left:-8px;
  right:-8px;
  bottom: -1px;
}

.tabs[data-type="Sativa-Hybrid"] .activeTab,
.tabs[data-type="Indica-Hybrid"] .activeTab,
.tabs[data-type="Hybrid"] .activeTab,
.tabs[data-type="Sativa-Hybrid"] .tab::before,
.tabs[data-type="Indica-Hybrid"] .tab::before,
.tabs[data-type="Hybrid"] .tab::before,
.tabs[data-type="Sativa-Hybrid"] .tab::after,
.tabs[data-type="Indica-Hybrid"] .tab::after,
.tabs[data-type="Hybrid"] .tab::after {
  background-color: var(--hybridActive);
}
.tabs[data-type="Sativa-Hybrid"] .activeTab .tabBottom,
.tabs[data-type="Indica-Hybrid"] .activeTab .tabBottom,
.tabs[data-type="Hybrid"] .activeTab .tabBottom {
  border-bottom: 1px solid var(--hybridActive);
}

.tabs[data-type="Indica"] .activeTab,
.tabs[data-type="Indica"] .activeTab::before,
.tabs[data-type="Indica"] .activeTab::after {
  background-color: var(--indicaActive);
}
.tabs[data-type="Indica"] .activeTab .tabBottom {
  border-bottom: 1px solid var(--indicaActive);
}

.tabs[data-type="Sativa"] .activeTab,
.tabs[data-type="Sativa"] .activeTab::before,
.tabs[data-type="Sativa"] .activeTab::after {
  background-color: var(--sativaActive);
}
.tabs[data-type="Sativa"] .activeTab .tabBottom {
  border-bottom: 1px solid var(--sativaActive);
}

.tabs[data-type="Sativa-Hybrid"] .inActiveTab,
.tabs[data-type="Indica-Hybrid"] .inActiveTab,
.tabs[data-type="Hybrid"] .inActiveTab,
.tabs[data-type="Sativa-Hybrid"] .inActiveTab::before,
.tabs[data-type="Indica-Hybrid"] .inActiveTab::before,
.tabs[data-type="Hybrid"] .inActiveTab::before,
.tabs[data-type="Sativa-Hybrid"] .inActiveTab::after,
.tabs[data-type="Indica-Hybrid"] .inActiveTab::after,
.tabs[data-type="Hybrid"] .inActiveTab::after {
  cursor: pointer;
  background-color: var(--hybridInActive);
}

.tabs[data-type="Indica"] .inActiveTab,
.tabs[data-type="Indica"] .inActiveTab::before,
.tabs[data-type="Indica"] .inActiveTab::after {
  cursor: pointer;
  background-color: var(--indicaInActive);
}

.tabs[data-type="Sativa"] .inActiveTab,
.tabs[data-type="Sativa"] .inActiveTab::before,
.tabs[data-type="Sativa"] .inActiveTab::after {
  cursor: pointer;
  background-color: var(--sativeInActive);
}

/* Product Description via Dutchie */

.productDesc {
  margin: .375rem 0 0;
  line-height: 135%;
}

/* Product description New-Line conversion */
.productDesc :global .descBR {
  margin-bottom: 8px;
}

@media screen and (max-width:767px) {
  .productDesc {
    font-size: 1rem;
  }
}

@media screen and (max-width:600px) {
  .tabs .tab {
    padding: 4px 12px 6px;
  }
  .productDesc {
    font-size: .9375rem;
    line-height: 140%;
    margin-top: -6px;
  }
  .productDesc :global .descBR {
    margin-bottom: 10px;
  }
}

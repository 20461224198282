
.subscribeModal {
  position: relative;
  width: 430px;
  max-width: calc(100vw - 32px); /* also via MuiModal */
  background: #72b7f2 url("../../../public/img/zyp_subscribe_bg.jpeg") no-repeat center top;
  background-size: cover;
}
/* height for spinner */
.formWrap {
  min-height: 280px;
}
.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding-top: 48px;
  background-color: #fff;
}
.closeWrap {
  padding: 6px 0 16px;
  text-align: center;
}
.closeText {
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

/* Klaviyo form overrides */
.subscribeModal form {
  padding: 12px 12px 0 !important;
  background: transparent url("../../../public/img/Get30OffAsterix.png") no-repeat center 14px !important;
  background-size: 90% !important;
}
.subscribeModal form h1 {
  opacity: 0;
  height: 220px; /* for background image */
}
/* button on separate line */
.subscribeModal div[data-testid="form-row"] {
  display: block !important;
  text-align: center;
}

/* These are for the disclaimer line (*) */
.subscribeModal div[data-testid="form-row"]:nth-of-type(2) {
  padding: 0 4px !important;
}
.subscribeModal div[data-testid="form-row"]:nth-of-type(2) div[data-testid="form-component"] {
  padding: 0 !important;
}
.subscribeModal div[data-testid="form-row"]:nth-of-type(2) p {
  font-size: 13px !important;
  margin-bottom: 8px;
}
.subscribeModal form button {
  width: 100%;
  color: #fff;
  background-color: var(--zrPrimaryVeryDark);
}

/* Success page */
.subscribeModal .formSubmitted form {
  flex-wrap: wrap;
  background: none !important;
}
.subscribeModal .formSubmitted form:before {
  color: #fff;
  content: "YOU'RE SUBSCRIBED!";
  font-size: 38px;
  line-height: 110%;
  padding: 104px 40px 24px;
  flex-basis: 100%;
  font-weight: 900;
  text-shadow: 4px 4px 0 #3c719d;
}
.checkmark {
  display: none;
}
.formSubmitted .checkmark {
  display: block;
  position: absolute;
  left: 45px;
  top: 36px;
  height: 80px;
}
/* :first-of-type hides the default "Thanks for subscribing" h1 message */
.subscribeModal .formSubmitted div[data-testid="form-row"]:first-of-type {
  display: none !important;
}
.subscribeModal .formSubmitted div[data-testid="form-row"]:nth-of-type(2) {
  padding: 0 40px !important;
  margin-bottom: 12px;
}
.subscribeModal .formSubmitted div[data-testid="form-row"]:nth-of-type(2) p {
  text-align: left !important;
}
.subscribeModal .formSubmitted div[data-testid="form-row"]:nth-of-type(2) p span {
  font-size: 20px !important;
  line-height: 120%;
  font-weight: 900 !important;
  color: #fff;
  text-shadow: 2px 2px #3c719d;
}

/* Desktop tweaks */
@media screen and (min-width:600px) {
  .formWrap {
    min-height: 400px; /* for spinner */
  }
  .subscribeModal form {
    background-size: 90% !important;
  }
  .subscribeModal form h1 {
    height: 255px; /* for background image */
  }
  .subscribeModal div[data-testid="form-row"]:nth-of-type(2) p {
    font-size: 15px !important;
  }
  .formSubmitted .checkmark {
    height: 100px;
  }
  .subscribeModal .formSubmitted form:before {
    font-size: 48px;
    padding: 122px 40px 24px;
  }
  .subscribeModal .formSubmitted div[data-testid="form-row"]:nth-of-type(2) p span {
    font-size: 20px !important;
    font-weight: 900 !important;
  }
}
